import React, { useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import { Link, useHistory, useLocation } from 'react-router-dom'

import {
	Heading,
	Text,
} from '@blackglobe-cz/cluster-components'

import { Button, Logo, Stars } from 'components'
import { withSnackBarConsumer } from 'components/alerting/SnackBarProvider'
import api from '../utils/api'

function Register({ snackBarOpen }) {
	const [formData, setFormData] = useState({})
	const history = useHistory()
	const location = useLocation()

	const { mutate: registerUser, isLoading: isSending } = useMutation(
		formData => api.post('user/register', formData),
		{
			throwOnError: true,
			onError: err => {
				snackBarOpen({ severity: 'error', text: 'Invalid credentials' })
			},
			onSuccess: result => {
				console.log('success', result)
				const { from } = location.state || { from: { pathname: '/login' } }
				history.replace(from)
				snackBarOpen({
					severity: 'success',
					text: "Account created. We've sent a confirmation link to your email!",
				})
			},
		},
	)

	const handleSubmit = useCallback(
		async event => {
			event.preventDefault()
			try {
				if (isSending) return
				if (
					!(
						formData.email &&
						formData.password &&
						formData.name &&
						formData.surname
					)
				) {
					return snackBarOpen({
						severity: 'error',
						text: 'Enter all fields to continue',
					})
				}
				await registerUser(formData)
			} catch (err) {
				console.log('err', err)
			}
		},
		[isSending, formData, registerUser, snackBarOpen],
	)

	return (
		<div className='full-window-wrapper'>
			<Stars />
			<div className='flex flex-align-center flex-center full-height'>
				<div className='full-screen-form-wrapper'>
					<Logo />
					<form noValidate onSubmit={handleSubmit}>
						<Heading text='Register' />
						<label title='Email Address'>
							<input
								type='text'
								required
								id='email'
								name='email'
								autoComplete='email'
								autoFocus
								placeholder='Email'
								onChange={e =>
									setFormData({ ...formData, email: e.target.value })
								}
							/>
						</label>
						<label title='Password'>
							<input
								required
								type='password'
								id='password'
								name='password'
								autoComplete='current-password'
								placeholder='Password'
								onChange={e =>
									setFormData({ ...formData, password: e.target.value })
								}
							/>
						</label>
						<label title='Name'>
							<input
								required
								type='text'
								id='name'
								name='name'
								autoComplete='name'
								placeholder='Name'
								onChange={e =>
									setFormData({ ...formData, name: e.target.value })
								}
							/>
						</label>
						<label title='Surname'>
							<input
								required
								type='text'
								id='surname'
								name='surname'
								autoComplete='surname'
								placeholder='Surname'
								onChange={e =>
									setFormData({ ...formData, surname: e.target.value })
								}
							/>
						</label>
						<Button className='full-width' type='submit' label='Register' />
					</form>
					<div style={{ gridColumn: '2 / 3', textAlign: 'center' }}>
						<Text text={`Already have an account? `} />
						<Link to='/login' className='text-link'>
							<Text text='Login' />
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withSnackBarConsumer(Register)