import React from "react";
import { withSnackBarConsumer } from "./SnackBarProvider.js";

import "./messages.scss";

const SnackBar = ({ show, snackBarClose, message }) => {
  // console.log("rendering?", { show });
  return (
    show && (
      <div
        className={`snack ${message.severity || ""}`}
        onClick={() => snackBarClose()}
      >
        {" "}
        {message.text}
      </div>
    )
  );
};

export default withSnackBarConsumer(SnackBar);