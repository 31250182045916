import React from "react";

import "./Button.scss";

export default function Button({ type = "button", style = "default", className, label }) {
  return (
    <button type={type} className={`acc-button button-${style}${className ? ` ${className}` : ''}`}>
      {label}
    </button>
  );
}