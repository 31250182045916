import { QueryClient, QueryClientProvider } from 'react-query'
import PrivateRoute from './components/PrivateRoute'
import SnackBarProvider from './components/alerting/SnackBarProvider'
import Messages from './components/alerting/Messages'
import { BrowserRouter, Switch } from 'react-router-dom'
import SignIn from './screens/SignIn'
import Register from './screens/Register'
import RegisterCheck from './screens/RegisterCheck'
import Home from './screens/Home'
import PublicRoute from './components/PublicRoute'

import './App.scss'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
})

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<SnackBarProvider>
				<BrowserRouter>
					<Switch>
						<PublicRoute component={SignIn} path='/login' exact />
						<PublicRoute component={Register} path='/register' exact />
						<PublicRoute component={RegisterCheck} path='/register/:code' />
						<PrivateRoute component={Home} path='/' exact />
					</Switch>
				</BrowserRouter>

				<Messages />
			</SnackBarProvider>
		</QueryClientProvider>
	)
}

export default App