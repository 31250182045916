import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
	Heading,
	Text,
} from '@blackglobe-cz/cluster-components'

import { Button, Logo, Stars } from 'components'
import { withSnackBarConsumer } from 'components/alerting/SnackBarProvider'

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

function SignIn({ snackBarOpen }) {
	const [formData, setFormData] = useState({})
	const query = useQuery()
	const error = query.get('error')

	useEffect(() => {
		if (error) {
			snackBarOpen({ severity: 'error', text: 'Invalid credentials' })
		}
	}, [snackBarOpen, error])

	return (
		<div className='full-window-wrapper'>
			<Stars />
			<div className='flex flex-align-center flex-center full-height'>
				<div className='full-screen-form-wrapper'>
					<Logo />
					<form
						noValidate
						method='post'
						action={`${process.env.REACT_APP_API_URL}api/login?${
							query.get('from') ? `from=${query.get('from')}` : ''
						}`}
					>
						<Heading text='Sign in' />
						<label title='Email'>
							<input
								type='text'
								required
								id='email'
								name='email'
								autoComplete='email'
								placeholder='Email'
								autoFocus
								onChange={e =>
									setFormData({ ...formData, email: e.target.value })
								}
							/>
						</label>
						<label title='Password'>
							<input
								required
								type='password'
								id='password'
								name='password'
								autoComplete='current-password'
								placeholder='Password'
								onChange={e =>
									setFormData({ ...formData, password: e.target.value })
								}
							/>
						</label>
						<Button className='full-width' type='submit' label='Log In' />
					</form>
					<div style={{ gridColumn: '2 / 3', textAlign: 'center' }}>
						<Text text={'Don\'t have an account yet? '} />
						<Link to='/register' className='text-link'>
							<Text text='Register' />
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withSnackBarConsumer(SignIn)