import React from "react";

import "./Stars.scss";

export default function Stars() {
  return (
    <div className="stars-wrapper">
      <div className="stars-1"></div>
      <div className="stars-2"></div>
      <div className="stars-3"></div>
    </div>
  );
}