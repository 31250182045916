import React from 'react'

import { Heading } from '@blackglobe-cz/cluster-components'

import { withSnackBarConsumer } from '../components/alerting/SnackBarProvider'
import { hollers } from '../utils/hollers'

function Profile() {
	const data = window.CLUSTER?.getMyData ? window.CLUSTER.getMyData() : {}

	const time = new Date().getHours()
	const greeting = (() => {
		if (time < 6) return 'Hello'
		if (time < 12) return 'Good morning'
		if (time < 18) return 'Hello'
		if (time < 22) return 'Good evening'
		return 'Hello'
	})()
	const holler = hollers[Math.floor(Math.random() * hollers.length)]

	return (
		<div className='full-screen full-screen-centered'>
			<div className='greeting-wrapper'>
				<Heading
					level='1'
					text={`${greeting}${data.name ? `, ${data.name}` : ''}.`}
				/>
				<Heading level='3' className='holler' text={holler} />
			</div>
		</div>
	)
}

export default withSnackBarConsumer(Profile)