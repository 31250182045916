import React from 'react'
import api from '../utils/api'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { withSnackBarConsumer } from '../components/alerting/SnackBarProvider'

function RegisterCheck({ snackBarOpen }) {
	const history = useHistory()
	const location = useLocation()
	let { code } = useParams()

	const { mutate: confirmInvitation } = useMutation(
		() => api.put(`invitation/${code}`),
		{
			onError: err => {
				console.log(err)
				snackBarOpen({ severity: 'error', text: 'Registration could not be confirmed' })
			},
			onSuccess: result => {
				console.log(result)
				const { from } = location.state || { from: { pathname: '/login' } }
				history.replace(from)
				snackBarOpen({ severity: 'success', text: 'Registration confirmed. You may now log in!' })
			},
		},
	)

	useQuery(['registration', code], () => api.get(`invitation/${code}`), {
		onSuccess: result => {
			console.log('data', result)
			if (!result.data.need_password && !result.data.already_expired) {
				confirmInvitation()
			}
		},
		onError: err => {
			console.log('err', err)
			snackBarOpen({ severity: 'error', text: 'error' })
		},
	})

	return <div>Check</div>
}

export default withSnackBarConsumer(RegisterCheck)