import React, {useState} from "react";

const DEFAULT_STATE = {
    show: false,
    message: {}
};

const SnackBarContext = React.createContext(DEFAULT_STATE);

export default function SnackBarProvider(props) {

    const [state, setState] = useState(DEFAULT_STATE)

    const snackBarOpen = (message) => {
        setState({show: true, message})
    }
    const snackBarClose = () => {
        setState({show: false, message: {}})
    }
    return (
        <SnackBarContext.Provider
            value={{
                ...state,
                snackBarOpen,
                snackBarClose
            }}
        >
            {props.children}
        </SnackBarContext.Provider>
    )
}

export const withSnackBarConsumer = WrappedComponent => {
    return props => (
        <SnackBarContext.Consumer>
            {({show, message, snackBarOpen, snackBarClose}) => {
                const snackBarProps = {
                    show,
                    message,
                    snackBarOpen,
                    snackBarClose
                }
                return <WrappedComponent {...snackBarProps} {...props} />
            }}
        </SnackBarContext.Consumer>
    )
}