import React from "react";

export default function Logo({ background }) {
  return (
    <svg
      version="1.1"
      className="cluster-logo-shape-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
    >
      <path
        fill="#fff"
        d="M100,153.1L100,153.1c-29.3,0-53.1-23.8-53.1-53.1S70.7,46.9,100,46.9s53.1,23.8,53.1,53.1H190 c0-49.7-40.3-90-90-90c-49.7,0-90,40.3-90,90s40.3,90,90,90h0V153.1z"
      />
    </svg>
  );
}