import axios from 'axios'
import { getToken } from './token'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}api`,
})

instance.interceptors.request.use(function(config) {
	const accessToken = getToken()
	config.headers.Authorization = `Bearer ${accessToken}`

	return config
})

export default instance