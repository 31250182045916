export const hollers = [
	`I hope you have a wonderful day!`,
	`What's on plan today?`,
	`Let's conquer the world, shall we?`,
	`Live as if you were to die tomorrow. Learn as if you were to live forever.`,
	`Be who you are and say what you feel, because those who mind don’t matter and those who matter don’t mind.`,
	`Do what you can, with what you have, where you are.`,
	`If you cannot do great things, do small things in a great way.`,
	`Whenever you find yourself on the side of the majority, it is time to pause and reflect.`,
	`Either you run the day, or the day runs you.`,
	`Life shrinks or expands in proportion to one’s courage.`,
	`You must be the change you wish to see in the world.`,
	`Believe and act as if it were impossible to fail.`,
	`What you do speaks so loudly that I cannot hear what you say.`,
	`The best way to predict the future is to invent it.`,
	`Remember that happiness is a way of travel, not a destination.`,
	`Too many of us are not living our dreams because we are living our fears.`,
	`If you want to lift yourself up, lift up someone else.`,
	`A person who never made a mistake never tried anything new.`,
	`The person who says it cannot be done should not interrupt the person who is doing it.`,
	`Great minds discuss ideas; average minds discuss events; small minds discuss people.`,
	`You only live once, but if you do it right, once is enough.`,
	`If you tell the truth, you don’t have to remember anything.`,
	`If you look at what you have in life, you’ll always have more. If you look at what you don’t have in life, you’ll never have enough.`,
	`Remember no one can make you feel inferior without your consent.`,
	`For every minute you are angry you lose sixty seconds of happiness.`,
	`Being deeply loved by someone gives you strength, while loving someone deeply gives you courage.`,
	`There are two ways of spreading light: to be the candle or the mirror that reflects it.`,
	`The road to success and the road to failure are almost exactly the same.`,
	`Motivation is a fire from within. If someone else tries to light that fire under you, chances are it will burn very briefly.`,
	`Though no one can go back and make a brand new start, anyone can start from now and make a brand new ending.`,
]