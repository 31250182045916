import React, { useEffect, useState } from 'react'
import { isLogin } from '../utils/token'
import { withSnackBarConsumer } from '../components/alerting/SnackBarProvider'
import Profile from './Profile'

function Home() {
	const [panelInitialized, setPanelInitialized] = useState(false)

	useEffect(() => {
		if (isLogin() && !panelInitialized && window.CLUSTER) {
			window.CLUSTER.initPanel('panel-wrapper')
			setPanelInitialized(true)
		}
	}, [panelInitialized])

	return (
		<div>
			<div id='panel-wrapper' />
			<Profile />
		</div>
	)
}

export default withSnackBarConsumer(Home)